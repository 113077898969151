/* XAI-Heatmap */
.DivArea {
    margin-left: 255px;
    padding: 1%;
}
.subOrder {
    font-size: 1em;
}

.mainOrder {
    font-size: 1.5em;
    font-weight: bold;
}

.CamList {
    width: 20%;
    float: left;
    padding-right: 0.5%;
}

.ImageTaskDiv {
    display: inline-block;
    width: 80%;
    height: 660px;
    padding: 10px;
}

.JtagImage {
    width: 120px;
    margin-right: 10px;
    cursor: pointer;
}

.ImageDiv {
    border-bottom: 1px solid lightgray;
}

.ImageCheckDiv {
    float: right;
}

.ImageScrollDiv {
    width: 100%;
    height: 350px;
    margin-top: 10px;
}

.ImageScrollDivUl {
    white-space: nowrap;
    overflow-x: auto;
}

/* 이미지 클릭시 */
.JtagImageBorder {
    width: 120px;
    margin-right: 10px;
    border: 3px solid rgb(0, 153, 255);
    cursor: pointer;
}

.JtagImage {
    width: 120px;
    margin-right: 10px;
    cursor: pointer;
}

.DamImageBorder {
    width: 120px;
    height: 80px;
    margin-right: 10px;
    border: 3px solid rgb(0, 153, 255);
    cursor: pointer;
}

.DamImage {
    width: 120px;
    height: 80px;
    margin-right: 10px;
    cursor: pointer;
}

.GirderImageBorder {
    width: 120px;
    height: 216px;
    margin-right: 10px;
    border: 3px solid rgb(0, 153, 255);
    cursor: pointer;
}

.GirderImage {
    width: 120px;
    height: 216px;
    margin-right: 10px;
    cursor: pointer;
}

.toolSelect{
    /* padding-left:330px; */
    padding-left:19vw
}

.xai_table {
    width: 25%;
    float: left;
    padding-right: 2%;
    margin-right: 10px;
}
.originalImg{
    
    margin-left: 5vw;
    margin-right: 2vw;
    /* padding : 10%; */
    text-align: center;
    float : left;
    
}

.AirOriImg{
    width : 360px;
}


.resultImg{
    margin-left: 7vw; /* captioning 원본이미지 넣게되면 이부분 주석 처리*/
    width: 360px;
    text-align: center;
    float: left;
}
.nextBtn {
    width: 150px;
    margin-left: 20px;
}
.selectDiv{
    width:10rem;
    font-family: 'inter-pretendard';
}
.fsize{
    width: 100px;
    margin-right: 20px;
    /* text-align: center; */
}
