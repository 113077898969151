body {
    margin: 0;
    font-family: "Figtree", "Segoe UI", "Figtree", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    background: #ffffff;
    font-size: 16px;
}

.app {
    position: fixed;
    background-color: #0F6FB7;
}
h1 {
    color: #ffffff;
    margin: 50px auto;
    font-size: 40px;
}
.menu1 {
    margin-bottom: 40px;
    padding-top: 20px;
}
h2 {
    color: #ffffff;
    padding-left: 20px;
}

.MenuItem {
    color: white;
}

.MenuItemClick {
    color: white;
    border-left: 5px solid white;
    background-color: #095E9D;
    font-weight: 600;
}

.SubMenuItem {
    color: white;
    background-color: #0F6FB7;
}


/* Increase the specificity */
.MenuItem.Mui-selected {
color: blue;
background: blue;
}

.css-xak8d3 >.ps-menu-button {
    overflow: hidden;
}

.Logout {
    position: absolute;
    color: white;
    bottom: 2rem;
    left : 2rem;
    font-size: 1rem;
}

/* project sidebar */
.Logout2 {
    position: sticky;
    color: white;
    bottom: 0px;
    margin-top: 30rem;
    font-size: 1rem;
}

.overflowMenu::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}