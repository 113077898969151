.DivArea {
    margin-left: 255px;
    padding: 1%;
}

.subOrder {
    font-size: 1em;
}

.mainOrder {
    font-size: 1.5em;
    font-weight: bold;
}

.SetDiv {
    width: 100%;
    height: 77vh;
    padding: 0.5%;
}

.CamList {
    width: 20%;
    float: left;
    padding-right: 0.5%;
    margin-right : 80px;
}

.CamLine {
    border-bottom: 1px solid lightgray; 
}


.DetectorDiv {
    display: inline-block;
    width: 76%;
    height: 660px;
    padding: 10px;
} 

.DetectorTitle {
    font-weight: bold;
}

.DetectorImage {
    width: 100%;
    height: 250px;
    margin-top: 10px;
}

.nextBtnDiv {
    float: right;
    margin-right: 10px;
    margin-top: 40px;
}

.nextBtn {
    width: 150px;
    margin-left: 20px;
}

.downloadBtn {
    width: 180px;
    margin-left: 20px;
}

.ImageTaskDiv {
    display: inline-block;
    width: 80%;
    padding: 10px;
}

.ImageScrollDiv {
    width: 100%;
    height: 350px;
    margin-top: 10px;
}

.ImageScrollDivDrone {
    width: 100%;
    height: 230px;
    margin-top: 10px;
}

.ImageScrollDivUl {
    white-space: nowrap;
    overflow-x: auto;
}

.ImageDiv {
    border-bottom: 1px solid lightgray;
}

/* 이미지 클릭시 */
.JtagImageBorder {
    width: 120px;
    margin-right: 10px;
    border: 3px solid rgb(0, 153, 255);
    cursor: pointer;
}

.JtagImage {
    width: 120px;
    margin-right: 10px;
    cursor: pointer;
}

.selectDiv{
    width: 100px;
    margin-bottom: 5px;
}

.viewerDiv {
    text-align: center;
    /* white-space: nowrap;
    overflow-x: hidden; */
}

.canvasWrap {
    /* cursor: url('../../public/images/pencil.png'); */
    /* cursor: url('../../public/images/pencil.png'), auto; */
    cursor : crosshair;
}