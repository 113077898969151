.DivArea {
    margin-left: 255px;
    padding: 1%;
}

.CreateTitleDiv {
    width: 100%;
    height: 10vh;
}

.CreateTitleText {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    padding: 1em;
}

.subOrder {
    font-size: 1em;
}

.subDashbord {
    font-size: 1.3em;
    font-weight: 600;
}

.mainOrder {
    font-size: 1.5em;
    font-weight: bold;
}

.ImageDiv {
    border-bottom: 1px solid lightgray;
}

.ImageCheckDiv {
    float: right;
}

.DetectorDiv {
    display: inline-block;
    width: 80%;
    height: 660px;
    padding: 10px;
} 

.ImageScrollDiv {
    width: 100%;
    height: 350px;
    margin-top: 10px;
}

.ImageScrollDivDrone {
    width: 100%;
    height: 230px;
    margin-top: 10px;
}

.ImageScrollDivUl {
    white-space: nowrap;
    overflow-x: auto;
}

.ImageScrollDivLi {
    display :inline-block;
    margin-right: 10px;
}

.DashboardItem {
    height: 15em;
    color: #000;
    line-height: 15em;
    text-align: center;
    background: #d9e5ff;
}

.SetDiv {
    width: 100%;
    height: 75vh;
    padding: 0.5%;
}

.CamList {
    width: 10%;
    float: left;
    padding-right: 0.5%;
}

.CamLine {
    border-bottom: 1px solid lightgray;
}

.JtagImageDiv {
    display: inline-block;
    width: 90%;
}

.JtagImage {
    width: 120px;
    margin-right: 10px;
    cursor: pointer;
}

.droneImage {
    width:200px;
    margin-right: 10px;
}

.JtagImageBorder {
    width: 120px;
    margin-right: 10px;
    border: 3px solid rgb(0, 153, 255);
    cursor: pointer;
}

.JtagImageClick {
    width: 120px;
    margin-right: 10px;
    border: 2px solid black;
    cursor: pointer;
}

.AllBtn {
    width:100px;
    height: 30px;
    position: fixed;
    text-align: center;
}

.JtagBtn {
    width: 100px;
    height: 30px;
    margin-left: 100px;
    text-align: center;
}


.CreateTitleDiv {
    width: 100%;
    height: 10vh;
}

.CreateTitleText {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    padding: 1em;
}

.Projectbody {
    width: 100%;
    height: 75vh
}

.Createtable {
    font-size: 16px;
}

.Forms {
    height: 72vh;
    width: 100%  
}

.FormItem {
    padding: 0;
    margin: 0 0 1em;
}

.RadioButton {
    width: 20em;
    text-align: center
}
.CreateButton {
    margin-top: 100px;
    padding-bottom: 50px;
    margin-left: 1450px;
    width: 150px;
}


.ImageTaskDiv {
    display: inline-block;
    width: 80%;
    height: 660px;
    padding: 10px;
}

.JtagDiv {
    display: inline-block;
    width: 100%;
    height: 660px;
    padding: 10px;
}

.CamList {
    width: 20%;
    float: left;
    padding-right: 0.5%;
}

/* Upload Video Area */
.uploadArea {
    padding-bottom: 2vw;
}

/* Video Edit */

.player_wrapper {
    margin-left: 2rem;
    width: 766px;
    height: 396px;
    /* background: #154cc4; */

    /* Player ratio: 100 / (1280 / 720) */
}

.react_player {
    /* margin-top: 1vw; */
    margin-right: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 8px;
    border: 3px solid rgb(206, 211, 233);
}

.Slider_Area {
    margin-top: -3rem;
}

.playControll {
    font-size: 40px;
    /* padding-left: 10%; */
    /* background: #25c247; */
}

.timeFont {
    font-size: 10px;
    padding-top: 5%;
    padding-left: 50px;
    /* background: #9e2241; */
}

.checkbox {
    float: right;
    width:12rem;
    margin-top:-1.8rem;
    font-family: 'inter-pretendard';
    font-size: 0.875rem;
    /* border: 3px solid rgb(105, 129, 238); */
}
/*Upload*/
.uploadimg{
    height : 2vw;
    width : 4vw;
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.uploadText{
    display: flex;
    align-items: center;
    justify-content:center;
}
.upload{
    width:70vw;
    margin-bottom:1vw;
    border:3px solid rgb(57, 69, 119);
}
.uploadList{
    /* margin-top: 1vw; */
    /* border: 3px solid rgb(206, 211, 233); */
    width: 24.5rem;
    /* height: 26.75rem; */
}
@mixin filledStyle() {
    background-color: black;
    color: white;
}

@mixin alignCenter() {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -ms-flex-direction: column;
    justify-content: center;
    align-items: center;
}

.DragDrop{
    width: 80vw;
    height: 10vw;
    @include alignCenter();
    
}
/* .DragDrop {
    width: 100%;
    height: 100vh;
    @include alignCenter();
    &-File {
        width: 400px;
        height: 200px;
        border: 2px solid black;
        border-radius: 10px;

        @include alignCenter();
        cursor: pointer;
        transition: 0.12s ease-in;

        &-Dragging {
            @include filledStyle();
        }
    }
} */

.inputbox {
    display: flex;
    align-items: center;
    max-width: 600px;
    background: #fff;
    border: 1px solid #a0a0a0;
    border-radius: px;
    padding-left: 0.5rem;
    overflow: hidden;
    height: 33px;
    font-size: 14px;
    }

    .inputbox2 {
        display: flex;
        align-items: center;
        width: 30rem;
        background: #fff;
        border: 1px solid #a0a0a0;
        border-radius: px;
        padding-left: 0.5rem;
        overflow: hidden;
        height: 33px;
        font-size: 14px;
        }

.inputbox .suffix {
    font-weight: 300;
    width: 550px;
    font-size: 14px;
    color: rgb(88, 88, 88);
    cursor: not-allowed;
    margin-right: 10px;
    font-weight: 600;

}

.inputbox .suffixx {
    font-weight: 300;
    font-size: 14px;
    color: rgb(88, 88, 88);
    margin-right: 10px;
}

.inputbox input {
    flex-grow: 1;
    font-size: 14px;
    background: #fff;
    border: none;
    outline: none;
    padding: 0.5rem;
}

.inputbox:focus-within {
    border-color: #777;
}

/* Airport image upload */
.uploadImage1{
    width:800px;
    position: relative;
    margin-left: 4vw;
    height: 33vw;
    border: 3px solid rgb(206, 211, 233);
    
}
.uploadImage2{
    width:840px;
    height: 30vw;
    margin-left: 24vw;
    text-align: center;
    padding-top: 10vw;
    font-size: 25px;
    border: 3px solid rgb(206, 211, 233);
}

/* Imageupload  */
.filterDiv{
    margin-left: 15vw;
}

.filterScrollDiv {
    width: 100%;
    height: 250px;
    margin-top: 10px;
}

.selectDiv{
    width: 130px;
    margin-bottom: 5px;
}
.BridgeAreaDiv{
    width: 130px;
    margin-bottom: 5px;
    float:left;
}
.BridgeOptionDiv {
    width: 130px;
    margin-bottom: 5px;
}
.option{
    /* border: 3px solid rgb(243, 74, 8); */
    height: 2vw;
    width:500px
}
.BuildingFormItem {
    height: 45px;
}

.BuildingImgUpload {
    width: 250px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #9da5b3;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    float: left;
}

.submitBtnDiv {
    margin-right: 10px;
}

.submitBtn {
    margin-top: 5rem;
    margin-left: 37.25rem;
    /* float : right; */
}

.createBtn {
    margin-top: 2.5rem;
    margin-left: 96rem;
    float : right;
}

.DamImageBorder {
    width: 120px;
    height: 80px;
    margin-right: 10px;
    border: 3px solid rgb(0, 153, 255);
    cursor: pointer;
}

.DamImage {
    width: 120px;
    height: 80px;
    margin-right: 10px;
    cursor: pointer;
}

.GirderImageBorder {
    width: 120px;
    height: 216px;
    margin-right: 10px;
    border: 3px solid rgb(0, 153, 255);
    cursor: pointer;
}


.GirderImage {
    width: 120px;
    height: 216px;
    margin-right: 10px;
    cursor: pointer;
}