.FooterDiv{
  background-color: rgb(236, 235, 235);
  width: 100%;
  height: 100px;
  position: relative;
  transform : translateY(110%+200px);
  font-size: 10px;
  display: flex;
  justify-items: center;
  align-items: center;
}

.Footer {
  width: 70%;
  margin: 0 auto;
  color: rgb(88, 88, 88);
  display: flex;
  flex-direction: column;
}

.FooterTextDiv {
  float: left;
  text-align: center;
  line-height: 0.5rem;
  margin-left : 15%;
}
