/* :root { font-family : 'inter'; font-size : 16px; margin: 0 auto; } */

@font-face {
    font-family: inter-pretendard;
    src: url('../../public/assets/fonts/Pretendard/Pretendard-Regular.ttf');
}

@font-face {
    font-family: inter-pretendard;
    src: 'inter';
    unicode-range: U+0041-005A, U+0061-007A;
}

@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined");

html, body {
    margin : 0 auto;
    padding : 0 auto;
    /* font-family : 'inter'; */
    font-family : 'inter-pretendard';
    font-size : 16px;
    /* letter-spacing: -1px; */
}

.show { visibility:visible !important; opacity: 1 !important; }
#navigation .submenu-tab { padding-top:12px; }
.logo-light { filter: brightness(100); }
.font-white { color:#FFF; }
.font-bold { font-weight:bold; }
.alert-table-column { min-height:300px;font-weight:bold;text-align:center;font-size:1.1em;color:#000; }
.click-target { cursor:pointer; }
.color_red { color:#FF0000; }
.color_green { color:#00FF00; }
.color_orange { color:#FFCB00; }
body { font-size:1.1em !important; }
p { font-size:1em !important; }
.table th, .table td { border-top:none !important; }
.center { text-align:center !important;margin:0 auto !important; }
.submenu-tab li { float:left; }
.title_line th { background-color:#EFEFEF;text-align:center; }
.title_line td { text-align:center; }
.font1 { font-size:1px;}
.font2 { font-size:2px;}
.font3 { font-size:3px;}
.font4 { font-size:4px;}
.font5 { font-size:5px;}
.font6 { font-size:6px;}
.font7 { font-size:7px;}
.font8 { font-size:8px;}
.font9 { font-size:9px;}
.font10 { font-size:10px;}
.font11 { font-size:11px;}
.font12 { font-size:12px;}
.font13 { font-size:13px;}
.font14 { font-size:14px;}
.font15 { font-size:15px;}
.font16 { font-size:16px;}
.font17 { font-size:17px;}
.font18 { font-size:18px;}
.font19 { font-size:19px;}
.font20 { font-size:20px;}
.font21 { font-size:21px;}
.bodytext { padding:3px 3px 3px 3px;margin:3px 8px 3px 8px; }
.bgGray { background-color:#F9F9F9;text-align: center;padding:8px 3px 5px 3px; }
.bgHighLight { background-color:#FFCB00; height: auto; }

.img-card { position:relative; }
.img-card img { width:100%;min-width: 60px;max-width: 400px;height:auto; }
.img-card .xbtn { position:absolute;right:5px;top:1px;cursor:pointer; }
.imgfile-card { position:relative;float:left;overflow:hidden;background-color:#F9F9F9;padding:5px 5px 5px 5px;border-radius: 3px;margin:2px 2px 2px 2px; }
.imgfile-card img { border-radius: 3px;cursor:pointer; }
.imgfile-card .xbtn { position:absolute;right:5px;top:1px;cursor:pointer; }

.w-10 { width:10%; }
.w-20 { width:20%; }
.w-30 { width:30%; }
.w-40 { width:40%; }
.w-50 { width:50%; }
.w-60 { width:60%; }

.editor { min-height: 400px; }


/* Deep Inspector */
.page-view {
    /* margin : 2rem 15rem 2rem 22.5rem; */
    margin : 3rem auto 5.25rem 23rem;
    width: 85.5rem;
    height: 100%;
}

.data-grid {
    display: flex;
    padding-left : 0.25rem;
}

/* Header CSS */
.topbar { 
    width: 100%;
    height: 48px;
    background: linear-gradient(90deg, #1287BC 1%, #ffffff); 
    position: sticky;
    top : 0;
    z-index: 999;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
}

.topbar-wrapper {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
}

.title-box {  margin : auto 4rem; }

.title-font {
    font-size : 1rem; 
    font-weight : 700;
    color : #ffffff;
    white-space : nowrap;
}

.search-box {
    width: 25rem;
    height: 2rem;
    background-color: #FFFFFF9A;
    margin : auto 0;
    margin-left : 0.563rem;
    margin-top : 0.625rem;
    border-radius: 10px;
}

.search-text {
    /* margin : auto 1.1rem; */
    margin : auto 0;
    margin-top : -0.125rem;
    display: flex;
}

.search-input {
    /* margin-top : 0.563rem; */
    width: 21.475rem;
    font-family: 'inter-pretendard';
    border: none; 
    background: transparent;
    outline: none;
    color : #1287BC;
    font-size : 0.8rem;
    font-weight : 500;
    margin-top : 0.2rem;
    margin-left : 1rem;
    margin-right : 0.713rem;
}

.search-input::placeholder { color : #1287BC; }

.search-icon {
    font-size : 1.1rem;
    margin : 0 auto;
    margin-right : 0.713rem;
    margin-top : 0.45rem;
    color : #5C5C61;
}

.topbar-right {
    margin : auto;
    margin-left : auto;
    display: flex;
    margin-right: 4rem;
    margin-top : 0.75rem;
}

.bell-icon {
    color : #1287BC;
    margin-right : 1.5rem;
    font-size : 1.5rem;
}

.user-icon {
    color : #5C5C61;
    font-size : 1.25rem;
}

/* Sidebar CSS */
.sidebar {
    position: fixed;
}

.sidebar-box {
    width: 15rem;
    height: 50rem;
    background-color: #FFFFFF;
    margin : 3rem 4rem;
    border-radius: 10px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
}

a{
    color:inherit;
    text-decoration:none;
}

ul, li {
    list-style:none;
}


.clearfix:after{
    content:'';
    display:block;
    clear:both;
}

.snb{
    height: calc(100% - 155px);
    overflow-y:scroll;
    /* 스크롤바 안보이게 설정 */
    -ms-overflow-style:none;
    scrollbar-width:none;
}
.snb::-webkit-scrollbar{
    display: none;
}
.snb > ul {
    margin : 1rem auto;
    margin-left: -1.5rem;
    margin-right: 1rem;
}
.on > ul {
    display : none;
}
.snb > ul > li{
    position: relative;
    margin-bottom: 0.663rem;
}
.snb > ul > li > a{
    color : #5C5C61;
    font-size: 1rem;
    font-weight: bold;
    display: inline-block;
    position: relative;
    width: 13rem;
    height: 2.4rem;
    padding: 0.55rem 1rem;
    box-sizing:border-box;
}

.down-icon {
    float : right;
    margin-top : 0.3rem;
    font-size : 0.7rem;
    color : #1287BC;
}

.up-icon {
    float : right;
    margin-top : 0.3rem;
    font-size : 0.7rem;
    color : #ffffff;
}

.snb > ul > li.on > a{
    color: #ffffff;
    background: linear-gradient(90deg, #1287BC 1%, #9FD8EE); 
    border-radius: 3.375rem;
}

.snb > ul > li.on > a:hover{
    color: #ffffff;
    background: linear-gradient(90deg, #015F8A 1%, #1287BC); 
    border-radius: 3.375rem;
    box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    transition-property: background-color;
    transition-duration: 0.2s;
}

.snb > ul > li > a:hover{
    color: #29282D;
    border-radius: 3.375rem;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    transition-property: background-color;
    transition-duration: 0.2s;
}

.snb > ul > li > ul{
    display: none;
}

.snb > ul > li.on > ul{
    display: block;
}

.snb > ul > li > ul > li {
    margin-left : 1rem;
    list-style:disc;
}

.snb > ul > li.on > ul > li.on > a {
    color : #1287BC;
    font-weight: bolder;
}

.snb > ul > li > ul > li a{
    font-size: 0.9rem;
    font-weight: bold;
    display: block;
    margin : 0.5rem auto;
    color: #5C5C61;
}
.snb > ul > li > ul > li:first-child{
    padding-top: 2px;
}
.snb > ul > li > ul > li:last-child{
    padding-bottom: 0.85rem;
}


/* Tabbar CSS */

.tab-title {
    width: 10rem;
    font-size: 1.5rem;
    font-weight: 700;
    color : #29282D;
}

.tabbar-middle {
    margin-top : 2.25rem;
    margin-bottom : 2.5rem;
}

.tab-subtitle {
    margin-left : 0.625rem;
}

.tab-subname {
    display: inline-block;
    margin-right : 5.188rem;
    font-size: 1.15rem;
    color : #5C5C61;
}

.tab-subname > li {
    list-style:disc;
}

.tab-subname > li > a.on {
    font-weight: 700;
}

.tabbar-bottom {

}

/* table */

/* table 검색 */
.table-search-wrapper {
    display: flex;  
}

.table-search {
    display: inline-flex;
    margin-bottom: 4rem; 
    margin-right : 0.5rem;
    
}

.search-select {
    width: 13rem;
    height: 2rem;
    border: 1px solid #E9EBF0;
    border-radius: 10px;
}

.search-keyword {
    width: 13rem;
    border: 1px solid #E9EBF0;
    border-radius: 10px;
    height: 2rem;
}
.registration{
    position: absolute;
    width: 11rem;
    height: 2rem;
    background-color: #1287BC;
    margin-left: 68.25rem;
    border-radius:1.125rem;
    color: #FFFFFF;
    font-size : 0.95rem;
    font-weight: 700;
    text-align: center;
    line-height : 2rem;
    transition-property: background-color;
    transition-duration: 0.2s;
}

.registration:hover{
    position: absolute;
    width: 11rem;
    height: 2rem;
    background-color: #015F8A;
    margin-left: 68.25rem;
    border-radius:1.125rem;
    color: #FFFFFF;
    font-size : 0.95rem;
    font-weight: 700;
    text-align: center;
    line-height : 2rem;
    cursor: pointer;
}

select {
    width: 13rem;
    height: 2rem;
    border-radius: 10px;
    padding : 0 1rem;
    font-size: 0.8rem;
    font-weight: 400;
    color : #5C5C61;
    border : none;
    -webkit-appearance:none;
    background:transparent;
    position: relative;
    z-index: 2;
    /* background-color: #00FF00; */
}

select:focus {
    outline: none;
}

.select-button {
    width: 1rem;
    position: absolute;
    margin-left: 10.7rem;
    margin-top : -0.1rem;
    color : #5C5C61;
}

.select-button-size {
    width: 0.75rem;
    height: 0.703rem;
}

.select-button svg {
    padding : 0.7rem 0;
}

.search-keyword {
    width: 21rem;
    display: flex;
}
.search-keyword-input {
    width: 18rem;
    padding : 0.525rem 1rem;
    font-family: 'inter-pretendard';
    border: none; 
    background: transparent;
    outline: none;
    font-size: 0.8rem;
    font-weight: 400;
    color : #5C5C61;
}

.search-keyword-input::placeholder { color : #5C5C61; }

.search-keyword-icon {
    
    font-size : 0.9rem;
    margin : auto 0;
    margin-top : 0.55rem;
    margin-right: 1rem;
    margin-left: 0.1rem;
}
    

/* table 출력 */
.table {
    width: 100%;
    color : #5C5C61;
    table-layout: fixed;
    border-collapse : collapse;
}

.table th {
    /* padding: 0 0.75rem; */
    vertical-align: top;
    /* border-radius: 0.625rem; */
    font-size: 0.9rem;
    font-weight: 600;
    /* padding-right : 1.5rem; */
    white-space : nowrap;
}

.table td {
    padding: 0.5rem 0;
    vertical-align: top;
    border-radius: 0.625rem;
    white-space : nowrap;
    /* border-top: 1px solid #eaf0f7; */
}

.th-title {
    display: flex;
    width: 79rem;
    margin-bottom: 1.5rem;
}

.th-title-th {
    width: 72.75rem;
    display: flex;
    justify-content: space-between;
}

.th-title-th-check {
    width: 68.25rem;
    display: flex;
    justify-content: space-between;
}

.th-title th {
    /* margin-right : 1.375rem; */
    font-size: 0.8rem;
    font-weight: 600;
}

.th-title .th-row-num {
    width: 1.5rem;
    padding-left : 1.5rem;
    padding-right : 1.5rem;
}

.th-title .th-row-all {
    display: flex;
    width: 1.5rem;
    padding : -1rem 0;
    margin : -0.8rem 0;
    margin-right : 1rem;
}

.tr-row {
    /* width: 78.39rem; */
    width: 79rem;
    border: 1px solid #E9EBF0;
    margin-bottom: 0.25rem;
    border-radius: 0.625rem;
    display: flex;
    height: 2rem;
}

.tr-row-minus {
    /* width: 78.39rem; */
    width: 76rem;
    border: 1px solid #E9EBF0;
    margin-bottom: 0.25rem;
    border-radius: 0.625rem;
    display: flex;
    height: 2rem;
}

.tr-row-td {
    width: 72.5rem;
    margin : auto 1.5rem;
    display: flex;
    justify-content: space-between;
}

.tr-row-td-check {
    width: 68rem;
    margin : auto 1.5rem;
    display: flex;
    justify-content: space-between;
}


.tr-row-c {
    width: 80rem;
    border: 1px solid #E9EBF0;
    margin-bottom: 0.25rem;
    border-radius: 0.625rem;
    display: inline-flex;
    height: 2rem;
}

.tr-row-s {
    width: 80rem;
    border: 1px solid #E9EBF0;
    margin-bottom: 0.25rem;
    border-radius: 0.625rem;
    display: inline-flex;
    height: 2rem;
}



.button-box {
    display: none;
    margin: auto 0;
    margin-right : -1.3rem;
}

.button-box a {
    margin : auto 0.25rem;
    margin-right : 0.75rem;
    margin-top : 0.25rem;
}

.button-box span {
    font-size : 1.3rem;
    color : #5C5C61;
}

.tr-row:hover .button-box {
    display: inline-flex;
}

.tr-row-minus:hover .button-box {
    display: inline-flex;
}

.tr-row:hover {
    background-color: #E9EBF0;
    /* width: 84.625rem;  */
    width: 83.4rem; 
    /* -1.5 */
}

.tr-row-minus:hover {
    background-color: #E9EBF0;
    /* width: 84.625rem;  */
    width: 80.4rem; 
    /* -1.5 */
}

.tr-row-c:hover {
    background-color: #E9EBF0;
}

.tr-row-s:hover {
    box-shadow: 4px 4px 10px 2px rgba(0, 0, 0, 0.1);
}



.tr-row:hover td {
    color : #000;
    font-weight: 400;
}

.tr-row-minus:hover td {
    color : #000;
    font-weight: 400;
}

.tr-row .tr-row-num {
    width: 1.5rem;
    padding-left : 1.5rem;
}

.tr-row-minus .tr-row-num {
    width: 1.5rem;
    padding-left : 1.5rem;
}

.tr-row-all {
    width: 1.5rem;
    padding : -1rem 0;
    margin : -0.35rem 0;
    margin-right : 1rem;
}

.tr-row-c .tr-row-num {
    width: 2.5rem;
}

.tr-row-s .tr-row-num {
    width: 2.5rem;
}

.tr-row-check {
    width: 1rem;
    margin : 0.75rem 2.5rem 0.75rem 0.6rem;
}

.table td {
    font-weight: 400;
    font-size: 0.9rem;
    color : #5C5C61;
    word-break:break-all;
}

/* 테이블 셀 간격 여기부터 */
/* .row_col_${columns.key} */
.row_col_title {
    width: 24.25rem;
}

.row_col_email, .row_col_content {
    width: 18.75rem;
}

.row_col_recipient {
    width: 16rem;
}

.row_col_price, .row_col_giveDay {
    width: 15.25rem;
}

.row_col_name, .row_col_MasterName, .row_col_employee {
    width: 11.5rem;
}

.row_col_companyId, .row_col_company, .row_col_businessRegNo {
    width: 14rem;
}

.row_col_phone, .row_col_contact, .row_col_limitSize  {
    width: 10rem;
}

.row_col_date, .row_col_paymentDate {
    width: 7rem;
}

.row_col_role, .row_col_grade, .row_col_masterID, .row_col_projectId {
    width: 6.5rem;
}

.row_col_startDate, .row_col_endDate, .row_col_payment {
    width: 6rem;
}

.row_col_isEnabled, .row_col_status, .row_col_isAccept {
    width: 4.3rem;
}

.row_col_type {
    width: 4rem;
}

.mb-0, .my-0 {
    margin-bottom: 0 !important;
}

.col-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
}

thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    
}

tr {
    display: inline-flex;
    vertical-align: inherit;
    border-color: inherit;  
    
}



th {
    text-align: inherit;
}


svg {
    overflow: hidden;
    vertical-align: middle;
    margin-left: 0.5rem;
}

nav {
    display: block;
}

ol, ul, dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

li {
    display: list-item;
}

.row-item {
    display: flex;
    flex-wrap: wrap;
    outline: none !important;
}

.card {
    position: relative;
    margin-bottom: 24px;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,0.125);
    box-sizing: border-box;
}

.card-body {
    display: block;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    min-height: 1px;
}

.sort-button {
    color : #1287BC;
    width: 0.75rem;
    height: 0.703rem;
}

/* table pagenation */
.pagination {
    display: -webkit-box;
    display: flex;
    list-style: none;
    border-radius: 0.25rem;
    padding-left: 0rem;
    float : right;
    margin-top : 7.875rem;
    margin-right : 4.25rem;
}

.page-item {
    margin : auto 0;
    margin-left : 0.5rem;
    color : #5C5C61;
    font-weight: 600;
    font-size : 0.9rem;
}

.page-item span {
    font-weight: 500;
    font-size : 0.8rem;
    margin : auto 1rem;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color : #5C5C61;
    border : none;
    background-color: rgba(0, 0, 0, 0);
}




button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
    cursor: pointer;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

button, input {
    overflow: visible;
}

button, select {
    text-transform: none;
}

button {
    border-radius: 0;
}

button, [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #506ee4;
    border-color: #506ee4;
}


/* user-detail */
.detail-title {
    width: 79.5rem;
    display: flex;
    border-radius: 0.625rem;
    background: linear-gradient(90deg, #015F8A 1%, #1287BC);
    color : #ffffff;
    font-weight: 700;
    font-size: 1.15rem;
    padding: 0.625rem 0 0.625rem 1.5rem;
}

.back-btn {
    margin : 1rem auto 2.5rem 1rem;
    display: block;
    cursor: pointer;
}

.back-btn span {
    font-size : 0.8rem;
    color : #5C5C61;
    margin-right : 0.611rem;
    vertical-align: middle;
}

.profile-title{
    display: flex;
    justify-content: space-between;
    width: 79.5rem;
    margin-left : 0.5rem;
    margin-right : 1rem;
}

.account{
    display: inline-flex;
}

.account span{
    font-size:3rem;
    color : #5C5C61
}

.account-text {
    margin : auto 0;
    font-weight: 700;
    font-size: 1.15rem;
    color : #29282D;
    margin-left : 1rem;
}

.approval {
    margin : auto 0;
}

.approval span {
    margin-left : 2.5rem;
}

.approval-name {
    color : #5C5C61;
    font-weight: 500;
}

.approval-data {
    color : #5C5C61;
    font-weight: 700;
}

.section-line {
    width: 80.5rem;
    height: 1px;
    background-color: #E9EBF0;
    margin-top: 1.25rem;
    margin-bottom: 2rem;
    margin-left : 0.5rem;
}

.btn-section-line {
    width: 80rem;
    height: 1px;
    background-color: #E9EBF0;
    margin-top: 6.4rem;
    margin-bottom: 1.5rem;
}

.profile-data {
    display: flex;
    width: 76.5rem;
    color : #5C5C61;
    font-size: 0.9rem;
    justify-content: space-between;
    margin-left : 0.5rem;
    margin-bottom : 2.5rem;
}

.profile-data span{
    color : #29282D;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 0.5rem;
    
}

.profile-data input{
    height: 2rem;
    color : #29282D;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 0.5rem;
    border-radius: 10px;
    padding-left : 0.625rem;
    /* padding: 0.375rem 0.625rem; */
    font-family: 'inter-pretendard';
}

.grade-data input{
    width: 5.9rem;
}

.status-data {
    display: flex ;
}

.status-data .btn-inline {
    width: 3.5rem;
    display: flex ;
    margin-left: 1rem;
}

.status-data-title {
    text-align: center;
    margin : auto 0;
}

.confirm {
    width: 4rem;
    background-color: #ff9900;
    /* margin-right: auto; */
    height: 2rem;
    display: flex;
    color: #000;
    border-radius: 10px;
    border: 1px solid #E9EBF0;
    justify-content : center;
    line-height: 2rem;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 20px;
    font-weight: 700;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }

  .confirm.active {
    width: 3rem;
    display: flex;
    color: #000;
    background-color: #3bd100;
    border-radius: 20px;
    border: 1px solid #E9EBF0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    font-weight: 700
  }

  .enabled {
    width: 3rem;
    margin-right: auto;
    height: 2rem;
    margin: 0 auto ;
    display: flex;
    color: #fff;
    background-color: rgb(255, 82, 82);
    border-radius: 10px;
    border: 1px solid #E9EBF0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    justify-content : center;
    line-height: 2rem;
    margin-left: 0.5rem;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 20px;
    font-weight: 700;
  }
  .enabled.active {
    width: 3rem;
    margin-right: auto;
    height: 2rem;
    margin: 0 auto ;
    display: flex;
    border-radius: 10px;
    background-color: rgb(4, 104, 255);
    color: #fff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    justify-content : center;
    line-height: 2rem;
    margin-left: 0.5rem;
    cursor: pointer;
    font-weight: 700;
    font-size: 1rem;
    /* line-height: 24px; */
  }

.used-data-box {
    display: flex;
    justify-content: space-between;
    height: 18.125rem;
    margin : 0.5rem;
}

.used-project, .used-ask {
    width: 39.25rem;
    height: 18.125rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
}

.company-grid {
    display: flex;
}

.regist-company-img {
    width: 18.5rem;
    height: 26.5rem;
    border-radius: 0.625rem;
    border : 1px solid #E9EBF0;
    margin : 2rem;
    margin-left : 12.75rem;
    margin-right : -5rem;
    
}

.imageUpload {
    display: none;
}

.regist-exist-img {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .img-delete {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    margin-left : 13.5rem;
    border : none;
    margin-top : 0.5rem;
    margin-bottom : 0.5rem;
} */

/* img {
    width: 15rem;
    height: 23rem;
} */

.regist-no-img {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.no-img-icon {
    display: flex;
    
}
.no-img-icon span {
    font-size: 4.25rem;
    color : #E9EBF0;
}

.no-img-text {
    text-align: center;
    font-size: 0.8rem;
    color : #A2A2AA;
}

.registration-data-box {
    display: flex;
    justify-content: space-between;
    /* background-color: #00FF00; */
}

.pro-data-box {
    display: flex;
    justify-content: space-between;
    width: 81rem;
    /* background-color: #00FF00; */
}

.registration-div{
    width: 70%;
    height: 100%;
    margin: 0 auto ;
    margin-top: 2rem;
    margin-right : 5rem;
    margin-left:3rem;
}

.registration-re-box{
    width: 41rem;
}

.registration-re-div{
    width: 90%;
    height: 100%;
    margin: 0 auto ;
    margin-top: 2rem;
    margin-left: 5rem;
}

.com-title span {
    font-size : 1.25rem;
    cursor: pointer;
}



.com-select-box{
    width: 9.25rem;
    height: 7.5rem;
    margin: 0 auto ;
    display: flex;
    border-radius: 10px;
    background-color: #015F8A;
    color: #EFEFEF;
    box-shadow: 0 1px 10px 0 E9EBF0;
    border: 1px solid #E9EBF0;
    justify-content : center;
    line-height: 7.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
}



.registration-box{
    width: 200%;
}





.used-data-box .com-project {
    width: 41.5rem;
    height: 31rem;
    border-radius: 10px;
}

.used-title {
    color : #1287BC;
    font-weight: 700;
    padding:1.5rem 0 2rem 1.375rem;
}

.used-data {
    font-size: 0.9rem;
    color : #5C5C61;
    margin-left : 1.438rem;
    margin-right : 1.438rem;

}

.used-data .data-row div {
    margin-bottom : 0.75rem;
    margin-right: 2.5rem;
}

.row-large {
    width: 50%;
}
.data-row {
    display: flex;
    justify-content: space-between;
    margin-right: -2.5rem;
}

.used-data .chage-row div {
    margin-bottom : 0.7rem;
    margin-right: 2rem;
    /* background-color: #1287BC; */
    width: auto;
}
.chage-row {
    display: flex;
    justify-content: space-between;
    margin-right: -2.5rem;
}

.btn-skin-location {
    margin-top : 3rem;
    display: flex;
    float: right;
}

.btn-skin-location-block {
    margin-top : 6.125rem;
    display: block;
    margin-left : 72rem;
}

.btn-skin-location-block div {
    margin-bottom : 0.5rem;
}

.btn-skin {
    text-align: center;
    line-height: 2.5rem;
    font-size : 1rem;
}

.btn-skin div {
    width: 13rem;
    height: 2.5rem;
    border: 1px solid #1287BC;
    border-radius: 66px;
    margin-left : 0.5rem;
    font-weight: 700;
    cursor:pointer;
}

.delete-btn {
    color : #1287BC;
}

.delete-btn:hover {
    color : #015F8A;
    background-color:#E9EBF0;
    border: 1px solid #015F8A;
    transition-property: background-color;
    transition-duration: 0.2s;
}

.delete-de-btn {
    color : #015F8A;
    background-color:#ffffff;
    border: 1px solid #015F8A;
    transition-property: background-color;
    transition-duration: 0.2s;
    margin-top: 12rem;
}

.update-btn {
    color : #ffffff;
    background-color: #1287BC;
}

.update-btn:hover {
    color : #ffffff;
    background-color: #015F8A;
    border: 1px solid #015F8A;
    transition-property: background-color;
    transition-duration: 0.2s;
}

.more-btn {
    display: flex;
    float : right;
    color : #ffffff;
    background-color: #1287BC;
    margin-top : 1.5rem;
    margin-right : 1.5rem;
    justify-content : center;
}

.more-btn:hover {
    color : #ffffff;
    background-color: #015F8A;
    border: 1px solid #015F8A;
    transition-property: background-color;
    transition-duration: 0.2s;
}

.confirm-btn {
    position: fixed;
    right : 14.5rem;
    top : 14rem;
    margin-left : -10rem;
}

.opacity {
    opacity:0.3;
}

.margin-up {
    margin-top : -1rem;
}

.search-subtitle {
   margin-bottom : 1rem; 
   display: flex;
}

.search-subtitle div {
    margin : auto 0;
    margin-right : 1rem;
}

.search-subtitle-title {
   font-weight: 700;
    font-size : 1.15rem;
    color : #29282D;
}

.search-subtitle-count {
    font-weight: 500;
    font-size : 1rem;
    color :#5C5C61;
}

.inquiry-detail-view {
    display: flex;
    justify-content: center;
}

.inquiry-data {
    /* width: 40.125rem; */
    width: 38.999rem;
    padding : 0.563rem;
    height: 100%;
}

.inquiry-content {
    width: 36.25rem;
    height: 16.125rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    margin-top : 1rem;
    padding : 1rem 1.5rem;
    font-size : 1rem;
    color : #5C5C61;
    font-weight: 500;
}

.inquiry-title {
    color : #29282D;
    font-size : 1.15rem;
    font-weight: 700;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
}

.inquiry-createat, .inquiry-recipient {
    color : #5C5C61;
    font-size : 0.9rem;
    font-weight: 500;
    margin-left: 1.5rem;
    margin-bottom: 0.5rem;
}

.inquiry-createat span, .inquiry-recipient span {
    font-weight: 700;
    margin-right : 1.5rem;
    width: 4rem;
}

.pay-box {
    display: flex;
    justify-content: space-between;
    background-color: #edfcff;
    padding: 1rem;;
}

.paytitle {
    color : #5C5C61;
    font-size: 1rem;
}

.paytitle span{
    font-size: 1.2rem;
    font-weight: 700;
}

.paycontent {
    font-size: 0.9rem;
}

.gradeButton{
    border: 1px soild green;
  background: light-green;
}


.com-non-select-box {
    width: 9.25rem;
    /* margin-right: auto; */
    height: 7.5rem;
    display: flex;
    color:#E9EBF0;
    border-radius: 10px;
    border: 1px solid #E9EBF0;
    justify-content : center;
    line-height: 7.5rem;
    cursor: pointer;
    /* border: 1px solid #5C5C61; */
    border-radius: 20px;
  }

  .com-non-select-box.active {
    width: 9.25rem;

    display: flex;
    color: #1287BC;
    background-color: #FFFFFF;
    border-radius: 20px;
    border: 1px solid #E9EBF0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 20px;
    font-weight: 700

  }

  .com {
    width: 9.25rem;
    margin-right: auto;
    height: 7.5rem;
    margin: 0 auto ;
    display: flex;
    color:#E9EBF0;
    border-radius: 10px;
    border: 1px solid #E9EBF0;
    justify-content : center;
    line-height: 7.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
    border-radius: 20px;
  }
  .com.active {
    width: 9.25rem;
    margin-right: auto;
    height: 7.5rem;
    margin: 0 auto ;
    display: flex;
    border-radius: 10px;
    background-color: #FFFFFF;
    color: #1287BC;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    justify-content : center;
    line-height: 7.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
    font-weight: 700;
    font-size: 20px;
    /* line-height: 24px; */
  }
  .grade-select, .grade-option{
    width: 7rem;
    height: 2.25rem;
    border: 2px solid #000000;
    border-radius: 10px;
    color: #5C5C61;
    margin-left : 0.5rem;
    font-size: 1rem;
    font-weight: 700;
  }

  .grade-check-input{
    width: 13.5rem;
    height: 2rem;
    border: 1px solid #E9EBF0;
    border-radius: 10px;
    color: #5C5C61;
  }

.comStatus-select {
    width: 19rem;
    height: 2rem;
    border: 1px solid #E9EBF0;
    border-radius: 10px;
    color: #5C5C61;
  }

  .payStatus-select {
    width: 19rem;
    margin-left: 0rem;
    height: 2rem;
    border: 1px solid #E9EBF0;
    border-radius: 10px;
    color: #5C5C61;
  }


.selectt-button {
    width: 5rem;
    position: absolute;
    margin-left: 16.5rem;
    margin-top : 0.4rem;
    color : #5C5C61;
}

.column-select{
    /* background-color: #1287BC;
    width: 19rem; */
    cursor: pointer;

}
.grade-check-btn {
    margin-top: 0.2rem;
    width: 5rem;
    height: 1.7rem;
    color : #ffffff;
    background-color: #1287BC;
    font-size: 0.8rem;
    line-height: 1.7rem;
    text-align: center;
    font-family: 'Inter';
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;


    /* font-weight: lighter; */

}

.com-div{
    margin: 0 auto ;
    margin-top: 2rem;
}
.com-de-div{
    width: 500px;
    height: 418px;
    /* margin: 0 auto ; */
    margin-left: 9.5rem;
    margin-top: 2rem;
}


.pro-de-div{
    width: 50%;
    height: 418px;
    margin: 0 auto ;
    /* margin-left: 9.5rem; */
    /* margin-top: 2rem; */
    padding-top: 5rem;
}

.com-box{
    width: 80%;
    height: 3.5rem;
    margin: 0 auto ;
    display: flex;
    
}

.pro-box{
    width: 85%;
    height: 3.5rem;
    margin: 0 auto ;
    display: flex;
    
}

.pro-text-box{
    width: 2.5rem;
    height: 100%;
    text-align: left;
    margin-left: 0.5rem;
    line-height: 2rem;
}

.com-re-box{
    width: 80%;
    height: 3.5rem;
    /* margin: 0 auto ; */
    display: flex;
    margin-left: 5.5rem;
}

.com-re-grade-box{
    width: 80%;
    height: 7.5rem;
    margin-left: 5.5rem;
    display: flex;
    font-weight:600;
    margin-bottom: 1rem;
}

.com-grade-box{
    width: 88%;
    height: 7.5rem;
    margin-left: 4.1rem;
    display: flex;
    font-weight:600;
    margin-bottom: 1rem;
}
.com-de-box{
    width: 100%;
    height: 3.5rem;
    /* margin: 0 auto ; */
    display: flex;
}
.pay-de-box{
    width: 101%;
    height: 3.5rem;
    /* margin: 0 auto ; */
    display: flex;
}
.com-titlee{
    margin-top: 0.5rem;
    width: 11rem;
    /* color: #5C5C61; */
    height: 2rem;
    display: flex;
    font-weight:600;
    font-size: 1rem;
}

.com-de-titlee{
    margin-top: 0.5rem;
    width: 11rem;
    /* color: #5C5C61; */
    height: 2rem;
    display: flex;
    font-weight:600;
    font-size: 1rem;
    margin-left: 0.3rem;

}
.pay-de-title{
    width: 17.5rem;
    /* margin-right: auto; */
    height: 2rem;
    /* margin: 0 auto ; */
    display: flex;
    font-weight:600;
    font-size: 1rem;
    line-height: 2rem;
    margin-left: 0.2rem;
    /* margin-right: 50px; */

}

.com-title{
    width: 30rem;
    margin-right: auto;
    height: 2rem;
    margin: 0 auto ;
    display: flex;
    font-weight:600;
    font-size: 1rem;
    line-height: 2rem;

}

.com-de-title{
    width: 11.5rem;
    margin-right: auto;
    height: 2rem;
    margin: 0 auto ;
    display: flex;
    font-weight:600;
    font-size: 1rem;
    line-height: 2rem;
}
.com-content{
    width: 20rem;
    /* margin-right: auto; */
    height: 2rem;
    margin: 0 auto ;
    display: flex;
    color : #5C5C61;
    font-size: 1rem;   
}
.com-re-content{
    width: 20rem;
    height: 2rem;
    display: flex;
    color : #5C5C61;
    font-size: 1rem;   
    /* margin-right: 3.2rem; */
}

.com-de-content{
    width: 19rem;
    height: 2rem;
    margin: 0 auto ;
    display: flex;
    color : #5C5C61;
    line-height: 1rem;
    font-size: 1rem;
    background: #FFFFFF;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.com-de-content input{
    font-family: inter-pretendard;
    font-size : 1rem;
    padding-left : 0.5rem;
}

.com-de-content > span{
padding: 10px;
}

.com-de-gra-content{
    width: 17rem;
    height: 2rem;
    margin: 0 auto ;
    display: flex;
    color : #5C5C61;
    font-size: 1rem;
}

.com-de-sta-content{
    width: 17rem;
    height: 2rem;
    margin: 0 auto ;
    margin-right: 2rem;
    display: flex;
    color : #5C5C61;
    font-size: 1rem;
}



.com-de-grade-box{
    width: 100%;
    height: 7.5rem;
    margin: 0 auto ;
    display: flex;
    font-weight:600;
    margin-bottom: 1rem;
}

.used-de-ask {
    width: 29.5rem;
    height: 28.125rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    margin-right: 5.5rem;
    margin-top: 2rem;
}

.update-de-btn {
    color : #ffffff;
    background-color: #1287BC;
    margin-top: 12rem;
}

.update-de-btn:hover {
    color : #ffffff;
    background-color: #015F8A;
    border: 1px solid #015F8A;
    transition-property: background-color;
    transition-duration: 0.2s;
}

.comStatus-de-select {
    margin: 0;
    padding: 0;
    width: 19rem;
    height: 2rem;
    border: 1px solid #E9EBF0;
    border-radius: 10px;
    color: #5C5C61;
  }

  .table-de-search-wrapper {
    display: flex;  
}

.table-de-search {
    display: inline-flex;
    margin-bottom: 4rem; 
    margin-right : 0.5rem;
}
.table-de-search {
    display: inline-flex;
    margin-bottom: 4rem; 
    margin-right : 0.5rem;
}

.selectt-de-button {
    width: 3rem;
    position: absolute;
    margin-left: 16.5rem;
    margin-top : 0.4rem;
    color : #5C5C61;
}

.project-data-box {
    display: inline-flex;
}

.project-img {
    margin-left : 6.625rem;
}

.project-img img {
    width: 10rem;
    height: 9rem;
}

.project-name-box {
    width: 16.5rem;
    height: 4.75rem;
    border-radius: 0.625rem;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    margin-left : 3.375rem;
    margin-top : 1rem;
    padding-top : 1.25rem;
}

.project-name-title {
    font-size : 0.9rem;
    font-weight: 500;
    color : #5C5C61;
    margin-bottom : 0.5rem;
    margin-left : 1.25rem;
}

.project-name-content {
    color : #29282D;
    font-size : 1rem;
    font-weight: 500;
    margin-left : 1.25rem;
}

.project-content-box {
    width: 16.5rem;
    height: 13.75rem;
    border-radius: 0.625rem;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    margin-left : 3.375rem;
    margin-top : 1rem;
    padding-top : 1.25rem;
}

.project-company, .project-manager, .project-date, .project-status {
    display: inline-flex;
    margin-bottom: 2.25rem;
}

.project-company-title, .project-manager-title, .project-date-title, .project-status-title {
    margin-left : 1.25rem;
    width: 4.5rem;
    font-size : 0.9rem;
    color : #5C5C61;
}

.project-company-content, .project-manager-content, .project-date-content, .project-status-content {
    margin-left : 0.5rem;
    width: 9rem;
    font-size : 1rem;
    color : #29282D;
}

.project-detail-box {
    width: 22.5rem;
    height: 32rem;
    border-radius: 0.625rem;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    margin-left : 1.5rem;
    padding-top : 0.25rem;
}

.project-speed, .project-fps, .project-direction, .project-set, .project-camera, .project-span, .project-spanlen, .project-ttype, .project-twidth, .project-tlength, .project-maxcrack, .project-overlap {
    display: flex;
    margin-top : 1rem;
    align-items: center;
}

.project-speed-title, .project-fps-title, .project-direction-title, .project-set-title, .project-camera-title, .project-span-title, .project-spanlen-title, .project-ttype-title, .project-twidth-title, .project-tlength-title, .project-maxcrack-title, .project-overlap-title {
    width: 8.5rem;
    font-weight: 400;
    font-size : 0.8rem;
    color : #5C5C61;
    margin-right : 1rem;
    margin-left : 1.25rem;
}

.project-speed-content, .project-fps-content, .project-direction-content, .project-set-content, .project-camera-content, .project-span-content, .project-spanlen-content, .project-ttype-content, .project-twidth-content, .project-tlength-content, .project-maxcrack-content, .project-overlap-content {
    width: 7rem;
    font-weight: 500;
    font-size : 0.9rem;
    color : #29282D;
    text-align: end;
}

.project-speed-unit, .project-fps-unit, .project-set-unit, .project-camera-unit, .project-span-unit, .project-spanlen-unit, .project-twidth-unit, .project-tlength-unit, .project-maxcrack-unit, .project-overlap-unit {
    width: 3rem;
    font-weight: 400;
    font-size : 0.8rem;
    color : #5C5C61;
    margin-left : 0.5rem;
}

.box-section-line {
    height: 1px;
    width: 20rem;
    background-color: #E9EBF0;
    margin : 1.25rem auto;
    margin-left : 1.25rem;
}

.project-result-box {
    margin-left : 3rem;
}
.project-usage-title {
    font-size: 0.9rem;
    font-weight: 600;
    color : #5C5C61;
    margin-bottom: 2rem;
}

.project-usage-content {
    display: flex;
}

.project-prograss {
    margin-right: 1.5rem;
}

.project-prograss-title {
    font-size: 0.9rem;
    font-weight: 600;
    color : #1287BC;
    margin-bottom: 1.313rem;
    text-align: center;
}

.project-prograss-content {
    position: relative;
    display:inline-block;
    width: 9.5rem;
    height: 9.5rem;
    border-radius: 50%;
    transition: 0.3s;
    background: linear-gradient(90deg, #1287BC 0%, #9FD8EE 100%);
}

.percent {
    background: #fff;
    display : block;
    position: absolute;
    top:50%; left:50%;
    width:7.673rem; height:7.673rem;
    border-radius: 50%;
    text-align:center; 
    line-height: 7.673rem;
    font-size:3rem;
    transform: translate(-50%, -50%);
    color : #015F8A;
    font-weight: 600;
}

.percent span {
    font-size: 1.513rem;
    font-weight: 400;   
}

.project-dashboard-btn {
    position: relative;
    display:inline-block;
    width: 9.5rem;
    height: 9.5rem;
    border-radius: 50%;
    background: linear-gradient(90deg, #015F8A 0%, #1287BC 100%);
    text-align: center;
    margin-top : 2.4rem;
}

.project-dashboard-btn:hover {
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    background : #015F8A
}

.project-dashboard-content {
    display: inline-flex;
    justify-content: center;
}

.project-dashboard-content div {
    width: 3.75rem;
    color : #FFFFFF;    
    font-size: 1rem;
    font-weight: 600;
    margin : 3.5rem auto;
}

.project-dashboard-content span {
    color : #FFFFFF;
    font-size: 2.5rem;
    padding-left: 0.25rem;
    margin : 3.5rem auto;
}

.project-document-title {
    font-size: 0.9rem;
    font-weight: 600;
    color : #5C5C61;
    margin-top: 4rem;
    margin-bottom: 2rem;
}

.project-document-content {
    display: flex;
}

.project-download {
    width: 9.5rem;
    height: 10rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    text-align: center;
    margin-right : 1.5rem;
}

.project-download:hover {
    background : #E9EBF0;
}

.project-download:hover div {
    color : #015F8A
}

.project-download div {
    color : #1287BC;
    font-size : 1rem;
    font-weight: 600;
}

.project-download span {
    font-size: 3rem;
    color : #1287BC;
    margin-top: 2rem;
    margin-bottom : 0.75rem;
}

.send-detail-view {
    display: flex;
}

.recipient-list {
    width: 17rem;
    height: 36rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-right: 1rem;
}

.recipient-detail {
    width: 17rem;
    height: 36rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-right : 4rem;
}

.message-title-box, .message-recipient-box {
    display: flex;
    margin-bottom: 0.8125rem;
    align-items: center;
}

.message-content-box {
    display: flex;
    align-items: top;
}

.message-content-box .message-title{
    margin-top: 0.5rem;
}


.message-content-box .message-content {
    height: 25rem;
}

.message-title {
    width: 3rem;
    color : #5C5C61;
    font-weight: 600;
    font-size : 0.9rem;
}

.message-content {
    width: 38.5rem;
    height: 2rem;
    border: 1px solid #E9EBF0;
    border-radius: 0.625rem;
}

.message-content input {
    margin : 0.4rem 1rem;
    border: none;
    width: 36.5rem;
    font-family : 'inter-pretendard';
    font-size : 0.9rem;
}

.message-content input:focus {
    outline: none;
}

.message-content textarea {
    width: 36.5rem;
    height: 23.5rem;
    white-space: pre-wrap;
    margin : 0.4rem 1rem;
    border: none;
    font-family : 'inter-pretendard';
    font-size : 0.9rem;
    resize: none;
}

.message-content textarea:focus {
    outline: none;
}

.message-type-box {
    display: block;
    margin-top: 1.5rem;
    float: right;
    align-items: center;
}

.message-type {
    display: flex;
    margin-left: 10rem;
}

.message-type-title {
    font-size: 0.9rem;
    font-weight: 600;
    color : #5C5C61;
    width: 4rem;
}

.message-type-content {
    display: flex;
    align-items: center;
}

.message-type-content label {
    font-size : 0.9rem;
    font-weight: 500;
    color : #5C5C61;
    margin-left : 1rem;
}

.message-type-content input {
    margin-left : 0.25rem;
}

.send-btn {
    margin-top : 2.25rem;
    float : right;
}

.recipient-detail-box {
    margin : 3.5rem 1.5rem;
    text-align: center;
}

.recipient-detail-icon span {
    font-size: 3rem;
    color : #1287BC;
    margin-bottom: 0.5rem;
}

.recipient-detail-email {
    font-size: 1rem;
    font-weight: 600;
    color : #1287BC;
    margin-bottom: 2.5rem;
}

.recipient-detail-name, .recipient-detail-company, .recipient-detail-pn, .recipient-detail-grade {
    margin-bottom: 2rem;
}
.detail-name-title {
    font-size: 0.8rem;
    font-weight: 400;
    color : #5C5C61;
    margin-bottom: 0.5rem;
}

.detail-name-content {
    font-size: 0.9rem;
    font-weight: 600;
    color : #5C5C61;
}

.message-btn {
    margin-top: 4.5rem;
    margin-left : 1.5rem;
}

.message-select-btn {
    margin-top: 1.75rem;
    margin-left : 1.5rem;
}

.com-li > ul {
    display: none;
}

.com-li.active > ul {
    display: block;
}

.deep-li {
    display: block;
}

.deep-li.active {
    
}

.r-list {
    width: 14.5rem;
    height: 28rem;
    margin : 1.5rem 1.25rem;
    
}

.r-list ul {
    margin-left: -2.5rem;
    
}

.r-list > ul > li > ul > li {
    margin-left : 1rem;
    
}

.r-list ul > li > a {
    font-weight: 600;
    font-size : 0.9rem;
    color : #5C5C61;
    margin-right : 9rem;
}

.deep-li.active {

    
}


.r-list ul > li > ul > li > a {
    font-weight: 500;
    font-size : 0.8rem;
    color : #5C5C61;
}

.com-tab-title {
    display: flex;
    width: 15rem;
    font-size: 1.5rem;
    font-weight: 700;
    color : #29282D;
    margin-bottom: 0.5rem;
}

.com-tab-title .up {
    margin-left : 8rem;
    margin-top : 0.25rem;
    font-size : 0.625rem;
}

.com-tab-title .down {
    margin-left : 8rem;
    margin-top : 0.25rem;
    font-size : 0.625rem;
}

/* 로그인 */

.login-page-wrapper {
    display: flex;
    margin: 0 auto;
    width: 50rem;
    padding-top: calc(70px + 60px);
    padding-left : 25rem;
}

.container-fluid {
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
    margin-right: auto;
    margin-left: auto;   
}

.mt-5, .my-5 {
    margin-top: 3rem !important;
}
.mb-4, .my-4 {
    margin-bottom: 1.5rem !important;
}

.mb-3, .my-3 {
    margin-bottom: 1rem !important;
}

.mt-2, .my-2 {
    margin-top: 0.5rem !important;
}

.mb-0, .my-0 {
    margin-bottom: 0 !important;
}

.col-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
}

.text-muted {
    color: #a4abc5 !important;
}

.text-center {
    text-align: center !important;
}

.form-group {
    margin-bottom: 20px;
}

.form-group2 {
    margin-bottom: -8px;
}

.form-group label {
    font-weight: 400;
    color: #656d9a;
    font-size: 13px;
    display: inline-block;
    margin-bottom: 0.5rem;
}

.form-group2 label {
    font-weight: 400;
    color: #656d9a;
    font-size: 12px;
    display: inline-block;
    margin-bottom: 0.35rem;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
    cursor: pointer;
}

.btn-gradient-primary {
    background: linear-gradient(14deg, #0b7af3 0%, rgba(11,122,243,0.6));
    color: #fff;
    box-shadow: 0 7px 14px 0 rgb(11 122 243 / 50%);
    border: none;
}

.btn-round {
    border-radius: 8px;
    background: #0F6FB7;
}

.waves-effect {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-gradient-primary:focus, .btn-gradient-primary:focus {
    background: linear-gradient(14deg, #0b7af3 0%, rgba(11,122,243,0.6));
    color: #fff;
}

.auth-form .input-group>.form-control:not(:first-child) {
    border-radius: 0;
}

.auth-form .form-control {
    padding-right: 40px;
}

.input-group>.form-control {
    position: relative;
    -webkit-box-flex: 1;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0;
}

.auth-form .input-group>.form-control:not(:first-child), .auth-form .input-group>.custom-select:not(:first-child) {
    border-radius: 0;
}
.input-group>.form-control:not(:first-child), .input-group>.custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.auth-form .form-control {
    border-radius: 0;
    padding-right: 40px;
}
.input-group>.form-control, .input-group>.form-control-plaintext, .input-group>.custom-select, .input-group>.custom-file {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0;
}
.form-control {
    border-bottom: 1px solid #dddee0;
    transition: border-color 0s ease-out;
    display: block;
    width: 100%;
    height: calc(1.8em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: .845rem;
    font-weight: 400;
    line-height: 1.8;
    color: #303e67;
    background-clip: padding-box;
    color : 'white';
}

.form-control::placeholder {
    color : rgba(255, 255, 255, 0.50);
    font-weight: 400;
    font-family: 'inter-pretendard';
}

.form-group button {
    width: 100%;
    padding : 1rem;
}

.input-group input {
    margin-left : 1rem;
    width: 18rem;
    /* background-color: #00FF00; */
    border-radius: 10px;
}

/* progress */
.circular-progress {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inner-circle {
    position: absolute;
    width: calc(200px - 30px);
    height: calc(200px - 30px);
    border-radius: 50%;
    background-color: lightgrey;
  }
  
  .percentage {
    position: relative;
    font-size: var(--font-size);
    color: rgb(0, 0, 0, 0.8);
  }
  
  @media screen and (max-width: 800px) {
    :root {
      --progress-bar-width: 150px;
      --progress-bar-height: 150px;
      --font-size: 3rem;
    }
  }
  
  @media screen and (max-width: 500px) {
    :root {
      --progress-bar-width: 120px;
      --progress-bar-height: 120px;
      --font-size: 3rem;
    }
  }

/*   
.MuiTableRow-hover {
  color : red
} */